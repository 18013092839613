import { mock } from 'src/utils/axios';

let pipelines = [
    {
        id: 1,
        local_name: 'Pipeline Sales',
        crm_name: 'CRM Sales',
        crm_brand: 'Hubspot',
    }
];

mock.onGet(`${process.env.REACT_APP_JWT_API_URL}/api/users/me/pipelines`).reply(() => {
    return [200, pipelines];
});



