import { mock } from 'src/utils/axios';

const templates = [
    {
        id: 1,
        spiff_value_cents: 500,
        crm_name: 'CRM Sales',
        pipeline_name: 'Pipeline Sales',
    }
];

mock.onGet(`${process.env.REACT_APP_JWT_API_URL}/api/users/me/templates`).reply(() => {
    try {
        return [200, templates];
    } catch (err) {
        console.error('Error: ', err);
        return [500, { message: 'Encountered a server error' }];
    }
});