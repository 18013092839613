import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import OwnerRoute from 'src/components/OwnerRoute';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const UserMe = Loader(
  lazy(() => import('src/content/management/Users/me'))
);
const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Pipelines = Loader(lazy(() => import('src/content/management/Pipelines')));
const SinglePipeline = Loader(
  lazy(() => import('src/content/management/Pipelines/single'))
);
const Spiffs = Loader(lazy(() => import('src/content/management/Spiffs')));
const SingleSpiff = Loader(
  lazy(() => import('src/content/management/Spiffs/single'))
);
const Templates = Loader(lazy(() => import('src/content/management/Templates')));
const SingleTemplate = Loader(
  lazy(() => import('src/content/management/Templates/single'))
);
const Payouts = Loader(lazy(() => import('src/content/management/Payouts')));
const SinglePayout = Loader(
  lazy(() => import('src/content/management/Payouts/single'))
);

const managementRoutes = [
  {
    path: '/',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'me',
        element: <UserMe />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  {
    path: 'pipelines',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (<OwnerRoute>
          <Pipelines />
        </OwnerRoute>)
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':pipelineId',
            element: <SinglePipeline />
          }
        ]
      }
    ]
  },
  {
    path: 'spiffs',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Spiffs />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':spiffId',
            element: <SingleSpiff />
          }
        ]
      }
    ]
  },
  {
    path: 'templates',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: (<OwnerRoute>
          <Templates />
        </OwnerRoute>)
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':templateId',
            element: <SingleTemplate />
          }
        ]
      }
    ]
  },
  {
    path: 'payouts',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Payouts />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':payoutId',
            element: <SinglePayout />
          }
        ]
      }
    ]
  },
];

export default managementRoutes;
