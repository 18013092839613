import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Account

const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const accountRoutes = [
  {
    path: 'login-basic',
    element: <LoginBasic />
  },
  {
    path: 'recover-password',
    element: <RecoverPassword />
  },

  {
    path: 'register-basic',
    element: <RegisterBasic />
  },
];

export default accountRoutes;
