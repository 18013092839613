import { Box } from '@mui/material';

import { ReactComponent as GarlicLogo } from 'src/layouts/ExtendedSidebarLayout/Sidebar/garlic.svg';

function AppInit() {
  return (
    <Box
      mt={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <GarlicLogo />
    </Box>
  );
}

export default AppInit;
