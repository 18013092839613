import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone'
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone'

const menuItems = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: AnalyticsTwoToneIcon,
        link: '/extended-sidebar/dashboards/home',
        display: 'always'
      },
      {
        name: 'Commissions',
        icon: AttachMoneyTwoToneIcon,
        link: '/extended-sidebar/management/spiffs/list',
        display: 'always'
      },
      {
        name: 'Intégration',
        icon: BuildTwoToneIcon,
        link: '/extended-sidebar/management/pipelines/list',
        display: 'owner'
      },
      {
        name: 'Modèles',
        icon: BackupTableTwoToneIcon,
        link: '/extended-sidebar/management/templates/list',
        display: 'owner'
      },
      {
        name: 'Paie',
        icon: ReceiptTwoToneIcon,
        link: '/extended-sidebar/management/payouts/list',
        display: 'owner'
      },
    ]
  }
];

export default menuItems;
